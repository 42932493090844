@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Styling here */
@layer utilities {
  .force-text-alert,
  .force-text-alert * {
    color: #ff3d00 !important;
  }
}

body {
  background: white;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
}

.link {
  color: #308d8a;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.paging-dot {
  width: 16px;
  height: 16px;
}

.paging-dot > circle {
  r: 6px;
  cx: 8px;
  cy: 8px;
}

.table-tiny th,
.table-tiny td {
  padding-left: 2px;
  padding-right: 2px;
}
